import React, { useEffect, useState } from 'react';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState } from 'src/shared/components/data-table/dataTableTypes';
import ListMapViewTabs, { MapOrTable } from 'src/shared/components/list-map-view-tabs/ListMapViewTabs';
import Loader from 'src/shared/components/loader/Loader';
import useSamplingEvent from 'src/app/samples/sample-manifest/hooks/useSamplingEvent';
import TestTypeChip from 'src/shared/components/test-type-chip/TestTypeChip';
import { ReactComponent as CloseIcon } from 'src/assets/svg/general/close.svg';
import { ReactComponent as CompleteIcon } from 'src/assets/svg/general/complete.svg';
import { ReactComponent as NoneIcon } from 'src/assets/svg/general/none.svg';
import { formattedKitBarcode } from 'src/shared/helpers/kitHelpers';
import { SamplingKit } from 'src/shared/types';
import classNames from 'classnames';
import AddTestsDropdown from './components/add-tests-dropdown/AddTestsDropdown';
import RemainingTestTypes from './components/remaining-test-types/RemainingTestTypes';
import styles from './AddTestsToSample.module.scss';

const AddTestsToSample = () => {
    const [tab, setTab] = useState<MapOrTable>('list');
    return (
        <div className='flex flex-1 flex-col gap-2 overflow-y-auto'>
            <div className='flex justify-between border-b border-grey-40 pb-2'>
                <div className='flex flex-col gap-2'>
                    <div className='text-lg'>Add test(s)</div>
                    <div>Add at least one test to every sample before proceeding</div>
                    <div className='mb-[-9px]'>
                        <ListMapViewTabs tab={tab} onTabChange={setTab} isMapFirst={false} disableMap={true} />
                    </div>
                </div>
                <RemainingTestTypes />
            </div>
            <div className='flex flex-1'>
                {tab === 'map' && <div>Map</div>}
                {tab === 'list' && <MainContentList />}
            </div>
        </div>
    );
};

const columns: DataTableColumns = [
    {
        columnId: 'sampleId',
        title: '',
        isUnique: true,
    },
    {
        columnId: 'sampleName',
        title: 'Sample Name',
        width: '15%',
    },
    {
        columnId: 'kitId',
        title: 'Kit ID',
        width: '15%',
    },
    {
        columnId: 'habitat',
        title: 'Habitat',
        width: '15%',
    },
    {
        columnId: 'addTests',
        title: 'Add Test(s)',
        renderer(params) {
            return <AddTestsDropdown samplingKitId={params.rowData[0] as string} />;
        },
    },
    {
        columnId: 'tests',
        title: 'Tests',
        width: '50%',
    },
    {
        columnId: 'readyToSubmit',
        title: 'Ready to Submit',
        width: '140px',
    },
];

const MainContentList = () => {
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        rowsPerPage: Number.POSITIVE_INFINITY,
        hiddenColumnIds: new Set(['sampleId']),
    });
    const { samplingEvent, kitsReadyToAddTests, saveSamplingKit } = useSamplingEvent();

    const removeTestType = async ({ kit, habitatAssayKey }: { kit: SamplingKit; habitatAssayKey: string }) => {
        const newTestTypes = kit.testTypes.filter(test => test.habitatAssayKey !== habitatAssayKey);
        await saveSamplingKit({
            ...kit,
            testTypes: newTestTypes,
        });
    };

    useEffect(() => {
        const data = kitsReadyToAddTests.map(kit => {
            const testTypes = (
                <div className='text-primary flex gap-2'>
                    {kit.testTypes.length > 0 ? (
                        kit.testTypes.map(test => (
                            <div className='flex items-center' key={test.habitatAssayKey}>
                                <TestTypeChip testTypeKey={test.habitatAssayKey} />
                                <CloseIcon
                                    className='cursor-pointer'
                                    onClick={() =>
                                        removeTestType({
                                            kit: kit,
                                            habitatAssayKey: test.habitatAssayKey,
                                        })
                                    }
                                />
                            </div>
                        ))
                    ) : (
                        <div>None added</div>
                    )}
                </div>
            );

            const readyToSubmitIcon = (
                <div className='flex justify-center'>
                    {kit.testTypes.length > 0 ? (
                        <CompleteIcon data-testid='complete-icon' />
                    ) : (
                        <NoneIcon className='fill-grey-80' data-testid='none-icon' />
                    )}
                </div>
            );
            return [kit.id, kit.name, formattedKitBarcode(kit.barcode), kit.habitat, 'Add Tests Placeholder', testTypes, readyToSubmitIcon];
        });
        setTableState({ ...tableState, data });
    }, [samplingEvent]);

    if (!tableState.data) {
        return <Loader />;
    }

    const tableClassNames = classNames('flex-1 bg-white rounded-md mt-2', styles.table);
    const table = (
        <DataTable state={tableState} columns={columns} emptyStateMessage={'No samples to display.'} className={tableClassNames} />
    );

    return <div className='flex flex-1 overflow-y-auto'>{table}</div>;
};

export default AddTestsToSample;
