import graphApiSlice from 'src/shared/state/api-slices/graphApiSlice';
import { SamplingEvent, SamplingKit } from 'src/shared/types';
import {
    samplingEventQuery,
    SamplingEventQueryResult,
    updateSampleKitQuery,
    initiateSampleManifestEventQuery,
    submitSampleKitsForProcessingQuery,
} from './queries/sampleManifestQueries';
import { defaultSamplingKit } from '../sampleManifestSlice';

type SamplingEventQueryParams = {
    samplingEventId: string;
    projectId: string;
};

type UpdateSamplingKitParams = {
    projectId: string;
    samplingEventId: string;
    input: Omit<SamplingKit, 'status'>;
};

type SubmitSampleKitsForProcessingParams = {
    projectId: string;
    samplingEventId: string;
    kitIds: string[];
};

const sampleManifestGraphSlice = graphApiSlice.injectEndpoints({
    endpoints: builder => ({
        samplingEvent: builder.query<SamplingEventQueryResult, SamplingEventQueryParams>({
            query: params => ({
                body: samplingEventQuery,
                variables: params,
            }),
            transformResponse(baseQueryReturnValue) {
                const samplingEvent = baseQueryReturnValue.sampleManifest.samplingEvent.event;
                // Add a default value for all the kits
                const kits = (samplingEvent as SamplingEvent).kits.map(kit => {
                    const samplingKit = Object.fromEntries(Object.entries(kit).filter(([, value]) => value));
                    return { ...defaultSamplingKit, ...samplingKit };
                });

                return {
                    ...samplingEvent,
                    kits,
                };
            },
        }),
        updateSampleKit: builder.mutation<boolean, UpdateSamplingKitParams>({
            query: params => ({
                body: updateSampleKitQuery,
                variables: params,
            }),
            async onQueryStarted(arg, api) {
                const { queryFulfilled, dispatch } = api;

                const patchSamplingEvent = dispatch(
                    sampleManifestGraphSlice.util.updateQueryData(
                        'samplingEvent',
                        { samplingEventId: arg.samplingEventId, projectId: arg.projectId },
                        (draft: SamplingEventQueryResult) => {
                            if (draft) {
                                const kitIndex = draft.kits.findIndex(kit => kit.id === arg.input.id);
                                if (kitIndex !== -1) {
                                    draft.kits[kitIndex] = { ...draft.kits[kitIndex], ...arg.input };
                                }
                            }
                        }
                    )
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    patchSamplingEvent.undo();
                    console.error('Error updating sampling kit:', error);
                }
            },
        }),

        initiateSampleManifestEvent: builder.mutation<boolean, SamplingEventQueryParams>({
            query: params => ({
                body: initiateSampleManifestEventQuery,
                variables: params,
            }),
        }),

        submitSampleKitsForProcessing: builder.mutation<boolean, SubmitSampleKitsForProcessingParams>({
            query: params => ({
                body: submitSampleKitsForProcessingQuery,
                variables: params,
            }),
        }),
    }),
});

export const {
    useSamplingEventQuery,
    useUpdateSampleKitMutation,
    useInitiateSampleManifestEventMutation,
    useSubmitSampleKitsForProcessingMutation,
} = sampleManifestGraphSlice;
export const { samplingEvent: fetchSamplingEvent } = sampleManifestGraphSlice.endpoints;
export default sampleManifestGraphSlice.reducer;
