import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SamplingKit, SamplingKitStatus } from 'src/shared/types';

export const defaultSamplingKit: SamplingKit = {
    id: '',
    name: '',
    barcode: '',
    latitude: '',
    longitude: '',
    sampler: '',
    sampledAt: '',
    habitat: '',
    notes: '',
    volumeFiltered: null,
    combinedVolumeFiltered: null,
    noOfSubSamples: null,
    preservationMethod: '',
    collectionMethod: '',
    samplingDuration: null,
    testTypes: [],
    status: SamplingKitStatus.READY_TO_SAMPLE,
};

export type SampleManifestState = {
    selectedSamplingEventId: string;
    selectedSamplingKitId: string;
    isTermsAndConditionsAccepted: boolean;
    isSamplingEventCompleted: boolean | null;
};

export const initialState: SampleManifestState = {
    selectedSamplingEventId: '',
    selectedSamplingKitId: '',
    isTermsAndConditionsAccepted: false,
    isSamplingEventCompleted: null,
};

export const sampleManifestSlice = createSlice({
    name: 'sampleManifest',
    initialState,
    reducers: {
        selectSamplingKit(state, action: PayloadAction<string>) {
            state.selectedSamplingKitId = action.payload;
        },
        setselectedSamplingEventId(state, action: PayloadAction<string>) {
            state.selectedSamplingEventId = action.payload;
        },
        setTermsAndConditionsAccepted(state, action: PayloadAction<boolean>) {
            state.isTermsAndConditionsAccepted = action.payload;
        },
        setSamplingEventCompleted(state, action: PayloadAction<boolean | null>) {
            state.isSamplingEventCompleted = action.payload;
        },
    },
});

export const { selectSamplingKit, setselectedSamplingEventId, setTermsAndConditionsAccepted, setSamplingEventCompleted } =
    sampleManifestSlice.actions;

export default sampleManifestSlice.reducer;
