import React, { useEffect, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import useAuthService from 'src/shared/hooks/useAuthService';
import Dashboard from 'src/app/dashboard/Dashboard';
import Projects from 'src/app/projects/Projects';
import Customers from 'src/app/customers/Customers';
import Tables from 'src/app/tables/Tables';
import Insights from 'src/app/insights/Insights';
import UserManagement from 'src/app/admin/views/user-management/UserManagement';
import Login from 'src/app/login/Login';
import usePermissions from 'src/shared/hooks/usePermissions';
import { Resources, Access } from 'src/shared/types';
import { useAppDispatch, useAppSelector } from 'src/store';
import { batch } from 'react-redux';
import { getCurrentProjectId } from 'src/app/projects/views/project-list/state/projectListSelector';
import useProject from 'src/shared/hooks/useProject';
import Survey from 'src/app/surveys/Survey';
import Maps from 'src/app/maps/Maps';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import Samples from 'src/app/samples/Samples';
import { AppViews, IndividualRouteSetting, mapRoutesAvailability, surveyRoutesAvailability } from './routesConfig';
import { getAllRoutesConfig } from './state/routesSelector';
import { updateRoutesConfig } from './state/routesSlice';
import ProtectedRoute from './ProtectedRoute';
import { handleConfigUpdate } from './appRoutesHelper';

export const appRouteSettings: { [key in AppViews]: IndividualRouteSetting } = {
    customers: {
        name: 'Org',
        path: '/customers/*',
        handler: <Customers />,
    },
    projects: {
        name: 'Projects',
        path: '/projects/*',
        handler: <Projects />,
    },
    survey: {
        name: 'Survey',
        path: '/survey/*',
        handler: <Survey />,
    },
    admin: {
        name: 'Admin User Mgt.',
        path: '/admin/*',
        handler: <UserManagement />,
    },
    map: {
        name: 'Map',
        path: '/map/*',
        handler: <Maps />,
    },
    dashboard: {
        name: 'Dashboard',
        path: '/dashboard',
        handler: <Dashboard />,
    },
    insights: {
        name: 'EcoStats',
        path: '/insights/*',
        handler: <Insights />,
    },
    tables: {
        name: 'Tables',
        path: '/tables/*',
        handler: <Tables />,
    },
    login: {
        name: 'Login',
        path: '/login/*',
        handler: <Login />,
    },
    samples: {
        name: 'Samples',
        path: '/samples/*',
        handler: <Samples />,
    },
};

const AppRoutes = () => {
    const routesUpdatedRef = useRef(false);

    const routesConfig = useAppSelector(getAllRoutesConfig);
    const dispatch = useAppDispatch();
    const { isAuthenticated, hasGivenTncConsent } = useAuthService();
    const { currentCustomerId, currentCustomerName } = useCustomer();
    const currentProjectId = useAppSelector(getCurrentProjectId);
    const { currentProjectSummary } = useProject();

    const { hasPermission, hasProjectLevelPermission, hasCustomerLevelPermission, isFetchingPermissions, isCurrentUserInternal } =
        usePermissions();
    const isCustomerAvailable = Boolean(currentCustomerId);
    const {
        isProjectAvailable,
        isCurrentProjectEmpty,
        isFetchingCurrentCustomerProjects,
        isSurveyDesignAvailable,
        isHabitatInsightsProject,
        currentCustomerProjects,
    } = useProject();

    const location = useLocation();

    useEffect(() => {
        routesUpdatedRef.current = false;
    }, []);

    useEffect(() => {
        if (isFetchingPermissions || isFetchingCurrentCustomerProjects) {
            return;
        }
        const updates: any = [];
        const { appRoutesAvailability, adminRoutesAvailability, projectRoutesAvailability } = routesConfig;

        // App routes
        const hasPlatformPageAccess =
            isProjectAvailable &&
            hasPermission({
                to: Access.VIEW,
                resource: Resources.PLATFORM_PAGE,
            });
        const hasHabitatInsightsPageAccess =
            isProjectAvailable &&
            hasPermission({
                to: Access.VIEW,
                resource: Resources.HABITAT_INSIGHTS_PAGE,
            });

        updates.push(
            updateRoutesConfig({
                appRoutesAvailability: {
                    ...appRoutesAvailability,
                    projects: isCustomerAvailable,
                    survey: isCustomerAvailable,
                    admin: isCustomerAvailable,
                    customers: isAuthenticated,
                    dashboard: hasPlatformPageAccess && !isCurrentProjectEmpty,
                    insights:
                        (hasPlatformPageAccess && !isCurrentProjectEmpty) || (isHabitatInsightsProject && hasHabitatInsightsPageAccess),
                    tables: (hasPlatformPageAccess && !isCurrentProjectEmpty) || (isHabitatInsightsProject && hasHabitatInsightsPageAccess),
                    map: (hasPlatformPageAccess && !isCurrentProjectEmpty) || (hasHabitatInsightsPageAccess && isHabitatInsightsProject),
                    samples: hasPlatformPageAccess,
                },
            })
        );

        // Admin routes
        const isUserManagementAvailable = [
            isCustomerAvailable,
            [
                hasPermission({
                    to: Access.VIEW,
                    resource: Resources.USER,
                }),
                hasCustomerLevelPermission({
                    to: Access.VIEW,
                    resource: Resources.USER,
                    customerId: currentCustomerId,
                }),
                currentCustomerProjects?.some(project =>
                    hasProjectLevelPermission({
                        to: Access.VIEW,
                        resource: Resources.USER,
                        projectId: project.projectId,
                    })
                ),
            ].some(Boolean),
        ].every(Boolean);

        updates.push(
            updateRoutesConfig({
                adminRoutesAvailability: {
                    ...adminRoutesAvailability,
                    userManagement: isUserManagementAvailable,
                },
            })
        );

        // Projects routes config
        const isProjectDefinitionAvailable =
            isCustomerAvailable &&
            isProjectAvailable &&
            hasPermission({
                to: Access.VIEW,
                resource: Resources.PROJECT_DEFINITION,
            });

        const isNewProjectDefinitionAvailable =
            isCustomerAvailable &&
            hasPermission({
                to: Access.CREATE,
                resource: Resources.PROJECT_DEFINITION,
            });

        const canAdminProject =
            isCustomerAvailable &&
            hasPermission({
                to: [Access.CREATE, Access.UPDATE],
                customerId: currentCustomerId,
                resource: Resources.PROJECT_DEFINITION,
            });

        updates.push(
            updateRoutesConfig({
                projectRoutesAvailability: {
                    ...projectRoutesAvailability,
                    projectDefinition: isProjectDefinitionAvailable,
                    newProjectDefinition: isNewProjectDefinitionAvailable,
                    projectAdmin: canAdminProject,
                },
            })
        );

        // Map routes config
        updates.push(
            updateRoutesConfig({
                mapRoutesAvailability: {
                    ...mapRoutesAvailability,
                    samples: hasPlatformPageAccess && !isCurrentProjectEmpty,
                    habitatInsights: hasPlatformPageAccess || hasHabitatInsightsPageAccess,
                },
            })
        );

        // Survey routes config
        const canAdminSurveyDesignAvailable =
            isCustomerAvailable &&
            hasPermission({
                to: Access.VIEW,
                resource: Resources.SURVEY_DESIGN,
            });

        const isNewSurveyDesignAvailable =
            isCustomerAvailable &&
            hasPermission({
                to: Access.CREATE,
                resource: Resources.SURVEY_DESIGN,
            });

        const isSurveyDesignSummaryAvailable =
            isProjectAvailable &&
            isSurveyDesignAvailable &&
            isCustomerAvailable &&
            hasPermission({
                to: Access.VIEW,
                resource: Resources.SURVEY_DESIGN,
            });

        updates.push(
            updateRoutesConfig({
                surveyRoutesAvailability: {
                    ...surveyRoutesAvailability,
                    surveyAdmin: canAdminSurveyDesignAvailable,
                    newSurveyDesign: isNewSurveyDesignAvailable,
                    surveyDesignSummary: isSurveyDesignSummaryAvailable,
                },
            })
        );

        // Samples route config
        const isSamplesManifestAvailable = Boolean(currentProjectSummary?.samplingCadence?.events.length);

        updates.push(
            updateRoutesConfig({
                samplesRoutesAvailability: {
                    samples: true,
                    sampleManifest: isSamplesManifestAvailable,
                },
            })
        );

        batch(() => {
            updates.map(dispatch);
        });
        routesUpdatedRef.current = true;
    }, [
        isAuthenticated,
        currentProjectId,
        currentCustomerId,
        isFetchingPermissions,
        isProjectAvailable,
        isCurrentProjectEmpty,
        isFetchingCurrentCustomerProjects,
        isHabitatInsightsProject,
        currentCustomerProjects,
    ]);

    if (!routesUpdatedRef.current || isFetchingPermissions || isFetchingCurrentCustomerProjects) {
        return null;
    }

    const shouldRedirectToCustomersList = !isProjectAvailable && !isCustomerAvailable && routesConfig.appRoutesAvailability.customers;
    const shouldRedirectToProjects = isCustomerAvailable && !isProjectAvailable && routesConfig.appRoutesAvailability.projects;

    // Update GA config params
    const configUpdateParams = {
        location,
        isAuthenticated,
        currentProjectSummary,
        isCurrentUserInternal,
        currentCustomerName,
    };
    handleConfigUpdate(configUpdateParams);

    return (
        <Routes>
            {Object.keys(routesConfig.appRoutesAvailability).map(routeKey => {
                const redirectTo = window.location.pathname + window.location.search;
                let redirectPath = '/?redirectTo=' + encodeURIComponent(redirectTo);
                if (isAuthenticated && !hasGivenTncConsent) {
                    redirectPath = '/login/terms-of-use-declined';
                } else if (shouldRedirectToProjects) {
                    redirectPath = '/projects';
                } else if (shouldRedirectToCustomersList) {
                    redirectPath = '/customers';
                }

                const isAvailable = (isAuthenticated || routeKey === 'login') && routesConfig.appRoutesAvailability[routeKey as AppViews];
                const routeSetting = appRouteSettings[routeKey as AppViews];
                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={redirectPath}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/' element={<Login />} />
            <Route path='/login/*' element={<Login />} />
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    );
};

export default AppRoutes;
