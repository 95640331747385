import React from 'react';
import Modal from 'src/shared/components/modal/Modal';
import SubmittedImage from 'src/assets/images/samples/submitted.png';
import { ReactComponent as PrintIcon } from 'src/assets/svg/general/print.svg';
import { ReactComponent as ReturnIcon } from 'src/assets/svg/general/return-arrow.svg';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';

const SuccessfullySubmittedModal = () => {
    const navigation = useAppNavigation();

    return (
        <Modal
            classNames={{
                body: 'w-[650px]',
            }}
        >
            <div className='flex flex-col gap-6'>
                <div className='flex justify-center'>
                    <img height='160px' width='160px' src={SubmittedImage} alt='Successfully submitted' />
                </div>
                <div className='text-lg text-primary'>Your data has been successfully submitted!</div>

                <div className='text-sm text-grey-80'>
                    NatureMetrics will review your data submission over the coming days to ensure all the data meets our requirements to
                    create your report.
                    <br />
                    <br />
                    If you have remaining sample data to submit for this event, you can begin the submission process again from the sample
                    manifest home page
                    <br />
                    <br />
                    Please print these submitted data by clicking the option below or on the sample manifest home page
                </div>

                <div className='flex gap-3'>
                    <PrimaryButton className='flex gap-2 w-[240px]' disabled={true}>
                        <PrintIcon className='fill-white' /> Print
                    </PrimaryButton>

                    <SecondaryButton className='flex gap-2' onClick={navigation.toSampleManifest}>
                        <ReturnIcon /> Return to sample manifest home page
                    </SecondaryButton>
                </div>
            </div>
        </Modal>
    );
};

export default SuccessfullySubmittedModal;
