import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import BackNextNavigation from 'src/shared/components/back-next-navigation/BackNextNavigation';
import { noop } from 'lodash';
import { SampleManifestFormViews, sampleManifestFormViewSettings } from '../../SampleManifestForm';
import useSamplingEvent from '../../../hooks/useSamplingEvent';
import SubmitData from '../submit-data/SubmitData';

const SampleManifestBackNextNavigation = () => {
    const [searchParams] = useSearchParams();
    const viewFromUrl = searchParams.get('view');
    const samplingEventId = searchParams.get('samplingEventId') as string;
    const navigation = useAppNavigation();
    const { kitsReadyToAddTests, readyToSubmitKits } = useSamplingEvent();

    const currentStep = sampleManifestFormViewSettings[viewFromUrl as SampleManifestFormViews]?.step ?? 0;

    const nextFormView = Object.keys(sampleManifestFormViewSettings).find(
        key => sampleManifestFormViewSettings[key as SampleManifestFormViews].step === currentStep + 1
    );
    const previousFormView = Object.keys(sampleManifestFormViewSettings).find(
        key => sampleManifestFormViewSettings[key as SampleManifestFormViews].step === currentStep - 1
    );

    const totalSteps = Object.keys(sampleManifestFormViewSettings).length;

    const shouldDisableNext = [
        // Disable next button to add tests page if there no kits ready to add tests
        nextFormView === SampleManifestFormViews.ADD_TESTS && kitsReadyToAddTests.length === 0,
        // Disable next button to submit page if not all kits are ready to submit
        nextFormView === SampleManifestFormViews.SUBMIT && kitsReadyToAddTests.length !== readyToSubmitKits.length,
        currentStep === totalSteps - 1,
    ].some(Boolean);

    const shouldHideNext = currentStep === totalSteps - 1;

    return (
        <div className='flex gap-2'>
            <BackNextNavigation
                onBack={
                    previousFormView
                        ? () => navigation.toSampleManifestForm({ view: previousFormView as SampleManifestFormViews, samplingEventId })
                        : navigation.toSampleManifest
                }
                onNext={
                    nextFormView
                        ? () => navigation.toSampleManifestForm({ view: nextFormView as SampleManifestFormViews, samplingEventId })
                        : noop
                }
                disableNext={shouldDisableNext}
                hideNext={shouldHideNext}
            />

            {shouldHideNext && <SubmitData />}
        </div>
    );
};

export default SampleManifestBackNextNavigation;
