import { isEqual } from 'lodash';

import turfBuffer from '@turf/buffer';
import { Units, Feature } from '@turf/helpers';
const isValidCoordinate = (value: string | number, regex: RegExp, min: number, max: number): boolean => {
    const valueString = String(value).trim();

    // count the total number of decimal points
    const decimalCount = (valueString.match(/\./g) || []).length;
    if (decimalCount !== 1) return false;

    // Check if the value matches the format for a valid decimal number
    const isValidFormat = regex.test(valueString);

    if (!isValidFormat) return false;

    // Convert to number and check the range explicitly
    const numberValue = parseFloat(valueString);
    return numberValue >= min && numberValue <= max;
};

export const isValidLatitude = (lat: string | number): boolean => {
    const latitudeRegex = /^-?(?:[0-8]?\d(?:\.\d{1,8})?|90(?:\.0{1,8}))$/;
    return isValidCoordinate(lat, latitudeRegex, -90, 90);
};

export const isValidLongitude = (lon: string | number): boolean => {
    const longitudeRegex = /^-?(?:1[0-7]\d|\d{1,2})(?:\.\d{1,8})?$|^-?180(?:\.0{1,8})?$/;
    return isValidCoordinate(lon, longitudeRegex, -180, 180);
};

export const swapCoordinateLatLng = ([lng, lat]: [number, number]): [number, number] => [lat, lng];

type GetBufferedPolygonParams = {
    coordinates: [number, number][];
    units?: Units;
    radius?: number;
};

export const getBufferedPolygon = (params: GetBufferedPolygonParams) => {
    const { coordinates, units = 'kilometers', radius = 1 } = params;

    const workingCoordinates = [...coordinates];
    const firstCoordinate = workingCoordinates[0];
    const lastCoordinate = workingCoordinates.at(-1);
    if (!isEqual(firstCoordinate, lastCoordinate)) {
        workingCoordinates.push(workingCoordinates[0]);
    }

    const polygonFeature = {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Polygon',
            coordinates: [workingCoordinates], // Wrapping the coordinates array in another array as it's a polygon
        },
    } as Feature;

    // Create a buffer around the polygon using Turf.js
    const bufferedPolygon = turfBuffer(polygonFeature, radius, { units });

    return bufferedPolygon;
};
