import moment from 'moment';
import { useEffect, useState } from 'react';
import useSamplingEvent from 'src/app/samples/sample-manifest/hooks/useSamplingEvent';
import { isValidLatitude, isValidLongitude } from 'src/shared/helpers/coordinateHelpers';
import { getKitTypeFromBarcode, isAquaticMaxiKit, KitType } from 'src/shared/helpers/kitHelpers';
import { SamplingKit } from 'src/shared/types';

const useFieldDataForm = () => {
    const { selectedSamplingKit, samplingKits, saveSamplingKit, samplingEvent } = useSamplingEvent();
    const [samplingKitDraft, setSamplingKitDraft] = useState({
        ...selectedSamplingKit,
        sampledAt: selectedSamplingKit?.sampledAt || new Date().toISOString(),
    });

    useEffect(() => {
        setSamplingKitDraft({
            ...selectedSamplingKit,
            sampledAt: selectedSamplingKit?.sampledAt || new Date().toISOString(),
        });
    }, [selectedSamplingKit]);

    const { id, barcode = '' } = samplingKitDraft;

    const isCurrentSampleNameUnique = samplingKits.every(kit => kit.id === id || kit.name !== samplingKitDraft.name);
    const kitType = getKitTypeFromBarcode(barcode);
    const isAquaticKit = kitType === KitType.Aquatic || kitType === KitType.AquaticBacteria;
    const isMaxiAquaticKit = isAquaticMaxiKit(barcode);
    const isMarineSedimentKit = kitType === KitType.MarineSediment;
    const isSoilKit = kitType === KitType.Soil;
    const isBulkInvertebrateKit = kitType === KitType.BulkInvertebrates;

    const onSamplingKitChange = (newValues: Partial<typeof selectedSamplingKit>) => {
        // If the date is today's date, and if the time is greater than the current time, set the time to the current time
        if (newValues && newValues.sampledAt && new Date(newValues.sampledAt).toDateString() === new Date().toDateString()) {
            const newTime = new Date(newValues.sampledAt).getTime();
            const currentTime = new Date().getTime();
            if (newTime > currentTime) {
                const newDate = new Date(newValues.sampledAt);
                newDate.setHours(new Date().getHours());
                newDate.setMinutes(new Date().getMinutes());
                newValues.sampledAt = newDate.toISOString();
            }
        }

        setSamplingKitDraft({ ...samplingKitDraft, ...newValues });
    };

    const onKitIdChange = (newBarcode: string) => {
        const newKitType = getKitTypeFromBarcode(newBarcode);

        if (newKitType === KitType.BulkInvertebrates || newKitType === KitType.Aquatic) {
            onSamplingKitChange({
                barcode: newBarcode,
                testTypes: [],
            });
            return;
        }

        onSamplingKitChange({
            barcode: newBarcode,
            habitat: newKitType,
            collectionMethod: '',
            testTypes: [],
        });
    };

    const saveChanges = async () => {
        await saveSamplingKit(samplingKitDraft as SamplingKit);
    };

    const getValidationData = () => {
        if (!samplingKitDraft || !samplingEvent) {
            return {
                errors: {},
                warnings: {},
                totalErrors: 0,
                totalWarnings: 0,
            };
        }

        const samplingEventStartDate = new Date(samplingEvent.fromDate);

        const { name, sampler, sampledAt, latitude, longitude, notes } = samplingKitDraft as SamplingKit;

        const sampledAtDate = new Date(sampledAt);

        const errors: Partial<Record<keyof SamplingKit, string[]>> = {
            name: [],
            sampler: [],
            sampledAt: [],
            latitude: [],
            longitude: [],
            notes: [],
        };

        const warnings: Partial<Record<keyof SamplingKit, string[]>> = {
            volumeFiltered: [],
            combinedVolumeFiltered: [],
        };

        let totalErrors = 0;
        let totalWarnings = 0;

        // Sample Name
        // check if name is alphanumerical
        if (!/^[a-zA-Z0-9 ]*$/.test(name)) {
            errors.name?.push('Sample name cannot contain any special characters');
            totalErrors++;
        }
        if (!isCurrentSampleNameUnique) {
            errors.name?.push('This sample name has already been used in this event. You must enter a unique name for every sample');
            totalErrors++;
        }
        // Sampler name must be entered in Latin script with no special characters
        if (!/^[a-zA-Z0-9 ]*$/.test(sampler)) {
            errors.sampler?.push('Sampler name must be entered in Latin script with no special characters');
            totalErrors++;
        }

        // Sampled At
        // sampledAt can only be a date between sampling event start date and current date
        if (sampledAtDate < samplingEventStartDate || sampledAtDate > new Date()) {
            errors.sampledAt?.push(
                `Date must be after start of sampling event ${moment(samplingEventStartDate).format(
                    'DD MMM YYYY'
                )} and before today’s date ${moment().format('DD MMM YYYY')}`
            );
            totalErrors++;
        }

        // Latitude
        if (latitude && !isValidLatitude(latitude)) {
            errors.latitude?.push('Latitude must be between -90 to 90 and have at least one decimal place');
            totalErrors++;
        }

        // Longitude
        if (longitude && !isValidLongitude(longitude)) {
            errors.longitude?.push('Longitude must be between -180 to 180 and have at least one decimal place');
            totalErrors++;
        }

        // Notes
        if (!/^[a-zA-Z0-9 ]*$/.test(notes)) {
            errors.notes?.push('Field notes must be written in latin script with no special characters');
            totalErrors++;
        }

        // Volume Filtered
        if (isAquaticKit && samplingKitDraft.volumeFiltered && samplingKitDraft.volumeFiltered < 500) {
            warnings.volumeFiltered?.push('This is lower than the minimum recommended volume (500mL)');
            totalWarnings++;
        }

        // Combined volume Filtered
        if (isMaxiAquaticKit && samplingKitDraft.combinedVolumeFiltered && samplingKitDraft.combinedVolumeFiltered < 500) {
            warnings.combinedVolumeFiltered?.push('This is lower than the minimum recommended volume (500mL)');
            totalWarnings++;
        }

        return {
            errors,
            warnings,
            totalErrors,
            totalWarnings,
        };
    };

    return {
        samplingEvent,
        samplingKitDraft,
        isAquaticKit,
        isMaxiAquaticKit,
        isSoilKit,
        isBulkInvertebrateKit,
        isCurrentSampleNameUnique,
        isMarineSedimentKit,
        validationData: getValidationData(),
        onSamplingKitChange,
        onKitIdChange,
        saveChanges,
        kitType,
    };
};

export default useFieldDataForm;
