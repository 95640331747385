import { gql } from 'graphql-request';
import { SamplingEvent } from 'src/shared/types';

export const samplingEventQuery = gql`
    query samplingEvent($projectId: String!, $samplingEventId: String!) {
        sampleManifest {
            samplingEvent(projectId: $projectId, identifier: $samplingEventId) {
                event {
                    name
                    fromDate
                    toDate
                    year
                    identifier
                    processed
                    processedDate
                    status
                    kits {
                        id
                        name
                        barcode
                        latitude
                        longitude
                        sampler
                        sampledAt
                        habitat
                        notes
                        volumeFiltered
                        noOfSubSamples
                        preservationMethod
                        collectionMethod
                        samplingDuration
                        testTypes {
                            habitatName
                            assayName
                            habitatAssayKey
                        }
                        status
                    }
                }
            }
        }
    }
`;

export type SamplingEventQueryResult = SamplingEvent;

export const updateSampleKitQuery = gql`
    mutation updateSampleKit($projectId: String!, $samplingEventId: String!, $input: SampleKitInput!) {
        sampleManifest {
            updateSampleKit(input: $input, projectId: $projectId, eventIdentifier: $samplingEventId)
        }
    }
`;

export const initiateSampleManifestEventQuery = gql`
    mutation initiateSampleManifestEvent($projectId: String!, $samplingEventId: String!) {
        sampleManifest {
            initiateSampleManifestForEvent(projectId: $projectId, eventIdentifier: $samplingEventId)
        }
    }
`;

export const submitSampleKitsForProcessingQuery = gql`
    mutation submitSampleKitsForProcessing($projectId: String!, $samplingEventId: String!, $kitIds: [String!]!) {
        sampleManifest {
            submitSampleKitsForProcessing(projectId: $projectId, eventIdentifier: $samplingEventId, kits: $kitIds)
        }
    }
`;
