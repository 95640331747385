import { CMSHabitatAssayTypeWithoutImage } from '../cms';

export enum SamplingEventStatus {
    INITIATED = 'INITIATED',
    READY_TO_SAMPLE = 'READY_TO_SAMPLE',
    SAMPLES_PARTLY_SUBMITTED = 'SAMPLES_PARTLY_SUBMITTED',
    SAMPLES_SUBMITTED = 'SAMPLES_SUBMITTED',
}

export type SamplingEventBatch = {
    id: string;
    kits: string[];
    status: string;
};

export type SamplingEvent = {
    identifier: string;
    name: string;
    fromDate: string;
    toDate: string;
    year: number;
    processed: boolean;
    processedDate: string;
    status: SamplingEventStatus;
    kits: SamplingKit[];
    kitsSentAt: string;
    batches: SamplingEventBatch[];
};

export enum SamplingKitStatus {
    READY_TO_SAMPLE = 'READY_TO_SAMPLE',
    SAMPLING_IN_PROGRESS = 'SAMPLING_IN_PROGRESS',
    SUBMITTED = 'SUBMITTED',
}

export type SamplingKit = {
    id: string;
    name: string;
    barcode: string;
    latitude: string;
    longitude: string;
    sampler: string;
    sampledAt: string;
    habitat: string;
    notes: string;
    volumeFiltered: number | null;
    combinedVolumeFiltered: number | null;
    noOfSubSamples: number | null;
    preservationMethod: string;
    collectionMethod: string;
    samplingDuration: number | null;
    testTypes: CMSHabitatAssayTypeWithoutImage[];
    status: SamplingKitStatus;
};

export enum SamplingEventBatchStatus {
    UNSUBMITTED = 'Unsubmitted',
    PENDING = 'Pending',
    COMPLETED = 'Completed',
}
