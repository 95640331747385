import * as React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import styles from './BasicTimePicker.module.scss';

type BasicTimePickerProps = {
    label?: string;
    value: Date | null;
    disablePast?: boolean;
    disableFuture?: boolean;
    format?: string;
    ampm?: boolean;
    onChange: (value: Date | undefined) => void;
};

const BasicTimePicker = (props: BasicTimePickerProps) => {
    const { label, value, onChange, disablePast, disableFuture, ampm = false, format = 'hh:mm' } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
                label={label}
                value={value ? moment(value || null) : null}
                onChange={value => onChange(value?.toDate())}
                className={styles.basicTimePicker}
                format={format}
                disablePast={disablePast}
                disableFuture={disableFuture}
                ampm={ampm}
            />
        </LocalizationProvider>
    );
};

export default BasicTimePicker;
