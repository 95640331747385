import React, { forwardRef, InputHTMLAttributes, ForwardedRef } from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean;
    hideMaxLengthLabel?: boolean;
    icon?: React.ReactNode;
    footerHint?: string;
};

const Input = (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const { className: classNameFromProps, hideMaxLengthLabel, hasError, icon, footerHint, ...otherProps } = props;
    const className = classNames(
        styles.input,
        {
            [styles.hasError]: hasError,
            [styles.inputWithIcon]: icon,
        },
        classNameFromProps
    );

    const totalCharacters = String(otherProps.value || '').length;

    return (
        <>
            <div className={styles.inputContainer}>
                <input className={className} ref={ref} spellCheck={false} {...otherProps} />
                {icon && <div className={styles.icon}>{icon}</div>}
            </div>
            {!footerHint && otherProps.maxLength && !hideMaxLengthLabel && (
                <div className={styles.limit}>
                    {totalCharacters}/{otherProps.maxLength} characters
                </div>
            )}
            {footerHint && <div className={styles.limit}>{footerHint}</div>}
        </>
    );
};

export default forwardRef(Input);
