import React, { ReactNode } from 'react';

import { ReactComponent as OrgIcon } from 'src/assets/svg/general/organisation.svg';
import { ReactComponent as ProjectIcon } from 'src/assets/svg/general/project.svg';
import { ReactComponent as SamplingEventIcon } from 'src/assets/svg/general/sampling-event.svg';
// import { ReactComponent as DataRangeIcon } from 'src/assets/svg/general/data-range.svg';
import { ReactComponent as PlacePinIcon } from 'src/assets/svg/general/place-pin.svg';
import { ReactComponent as DnaIcon } from 'src/assets/svg/general/dna.svg';
import Select, { SelectOption } from 'src/shared/components/select/Select';
import useProject from 'src/shared/hooks/useProject';
import { SampleCountForTestType, SampleCountForTestTypes } from 'src/shared/types';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getselectedSamplingEventId } from '../../state/sampleManifestSelector';
import { setselectedSamplingEventId } from '../../state/sampleManifestSlice';
// import moment from 'moment';
// import { STANDARD_DATE_FORMAT } from 'src/shared/constants/dateConstants';

const Overview = () => {
    const { currentProjectSummary } = useProject();
    const { currentCustomerName } = useCustomer();
    const samplingCadence = currentProjectSummary?.samplingCadence;
    const estimatedSampleCountForTestTypes = currentProjectSummary?.estimatedSampleCountForTestTypes;
    const estimatedSampleCounts = currentProjectSummary?.estimatedSampleCounts;
    const [samplingEventOptions, setSamplingEventOptions] = React.useState<SelectOption[]>([]);
    const selectedSamplingEventId = useAppSelector(getselectedSamplingEventId);
    const dispatch = useAppDispatch();

    const setSelectedSamplingEvent = (value: string) => {
        dispatch(setselectedSamplingEventId(value));
    };

    React.useEffect(() => {
        if (samplingCadence) {
            const options = samplingCadence.events
                .filter(event => event.identifier)
                .map(event => ({
                    value: event.identifier,
                    label: event.name,
                }));

            setSamplingEventOptions(options);

            setSelectedSamplingEvent(options[0].value);
        }
    }, [samplingCadence]);

    const samplingEventDropdown = (
        <Select options={samplingEventOptions} selectedValue={selectedSamplingEventId} onChange={setSelectedSamplingEvent} width='200px' />
    );

    // TODO: Uncomment when sampling period is available
    // const selectedSamplingEventDetails = samplingCadence?.events.find(event => event.name === selectedSamplingEvent);

    // const samplingPeriod = (
    //     <div className='text-primary'>
    //         {`${moment(selectedSamplingEventDetails?.fromDate).format(STANDARD_DATE_FORMAT)} - ${moment(
    //             selectedSamplingEventDetails?.toDate
    //         ).format(STANDARD_DATE_FORMAT)}`}
    //     </div>
    // );

    return (
        <div className='flex flex-col shadow-lg p-4 rounded-lg bg-white gap-6'>
            <div className='text-primary text-lg'>Overview</div>
            <div className='flex border-b-grey-40 border-b pb-4'>
                <OverviewItem icon={<OrgIcon />} title='Organisation:' value={currentCustomerName} />
                <OverviewItem icon={<ProjectIcon />} title='Project:' value={currentProjectSummary?.projectName} />
                <OverviewItem icon={<SamplingEventIcon />} title='Sampling event' value={samplingEventDropdown} />
                {/* <OverviewItem icon={<DataRangeIcon />} title='Expected sampling period:' value={samplingPeriod} /> */}
            </div>

            <div className='flex pt-2'>
                <OverviewItem
                    icon={<PlacePinIcon />}
                    title='Number of samples to submit'
                    value={<span className='text-primary text-2xl'>{estimatedSampleCounts}</span>}
                />

                <div className='flex gap-2 flex-2'>
                    <DnaIcon className='fill-grey-80' />
                    <div>
                        <div className='text-grey-80'>Tests to be added</div>
                        <div className='text-primary'>
                            <TestsOverview estimatedSampleCountForTestTypes={estimatedSampleCountForTestTypes || []} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

type OverviewItemProps = {
    icon: ReactNode;
    title: ReactNode;
    value: ReactNode;
};

const OverviewItem = (props: OverviewItemProps) => {
    const { icon, title, value } = props;

    return (
        <div className='flex gap-2 flex-2 w-[290px]'>
            <div className='text-grey-80'>{icon}</div>
            <div>
                <div className='text-grey-80'>{title}</div>
                <div className='text-primary'>{value}</div>
            </div>
        </div>
    );
};

type TestsOverviewProps = {
    estimatedSampleCountForTestTypes: SampleCountForTestTypes;
};

const TestsOverview = (props: TestsOverviewProps) => {
    const { estimatedSampleCountForTestTypes } = props;

    return (
        <div className='flex gap-4 flex-wrap'>
            {estimatedSampleCountForTestTypes.map((test, index) => {
                return (
                    <>
                        <TestItem test={test} key={index} />
                        {estimatedSampleCountForTestTypes[index + 1] && (
                            <div
                                className='inline-block h-[40px] min-h-[1em] w-0.5 self-stretch bg-primary'
                                key={`separator - ${index}`}
                            ></div>
                        )}
                    </>
                );
            })}
        </div>
    );
};

const TestItem = (props: { test: SampleCountForTestType }) => {
    const { test } = props;

    return (
        <div className='flex gap-2 flex-2 text-primary'>
            <div className='text-primary text-4xl'>{test.count}</div>
            <div>
                <div className='font-light'>{test.type.habitatName}</div>
                <div className='font-bold'>{test.type.assayName}</div>
            </div>
        </div>
    );
};

export default Overview;
