import { ChevronRight } from '@mui/icons-material';
import React, { useState } from 'react';
import { PrimaryButton } from 'src/shared/components/button/Button';
import { useAppSelector } from 'src/store';
import SuccessfullySubmittedModal from './components/successfully-submitted-modal/SuccessfullySubmittedModal';
import SubmitionErrorModal from './components/submission-error-modal/SubmitionErrorModal';
import { getCurrentSampleManifestState } from '../../../state/sampleManifestSelector';
import useSamplingEvent from '../../../hooks/useSamplingEvent';

const SubmitData = () => {
    const [submissionResult, setSubmissionResult] = useState('');

    const { isSamplingEventCompleted, isTermsAndConditionsAccepted } = useAppSelector(getCurrentSampleManifestState);
    const { submitSamplingKits } = useSamplingEvent();

    const onSubmit = async () => {
        const result = await submitSamplingKits();

        if (result === true) {
            setSubmissionResult('success');
        } else {
            setSubmissionResult('error');
        }
    };

    return (
        <div>
            <PrimaryButton onClick={onSubmit} disabled={isSamplingEventCompleted === null || !isTermsAndConditionsAccepted}>
                Submit data to Naturemetrics <ChevronRight />
            </PrimaryButton>
            {submissionResult === 'success' && <SuccessfullySubmittedModal />}
            {submissionResult === 'error' && <SubmitionErrorModal onClose={() => setSubmissionResult('')} />}
        </div>
    );
};

export default SubmitData;
