import React, { ReactNode, useEffect, useState } from 'react';
import { selectSamplingKit } from 'src/app/samples/sample-manifest/state/sampleManifestSlice';
import DataTable from 'src/shared/components/data-table/DataTable';
import { DataTableColumns, DataTableState, TableRowData } from 'src/shared/components/data-table/dataTableTypes';
import ExpandableSection from 'src/shared/components/expandable-section/ExpandableSection';
import ListMapViewTabs, { MapOrTable } from 'src/shared/components/list-map-view-tabs/ListMapViewTabs';
import { useAppDispatch } from 'src/store';
import { ReactComponent as PersonIcon } from 'src/assets/svg/general/person.svg';
import Loader from 'src/shared/components/loader/Loader';
import useSamplingEvent from 'src/app/samples/sample-manifest/hooks/useSamplingEvent';
import { getSamplingKitFieldDataStatus } from 'src/shared/helpers/kitHelpers';
import { SamplingKitStatus } from 'src/shared/types';
import BatchStatus, { SamplingEventBatchStatus } from './components/batch-status/BatchStatus';
import FieldDataStatusIndicator, { FieldDataStatus } from './components/field-data-status-indicator/FieldDataStatusIndicator';

const FieldDataMainContent = () => {
    const [tab, setTab] = useState<MapOrTable>('list');
    return (
        <div className='flex flex-1 flex-col gap-2 overflow-y-auto'>
            <div className='text-lg'>Add Field Data</div>
            <div className='border-b border-grey-40'>
                <ListMapViewTabs tab={tab} onTabChange={setTab} isMapFirst={false} disableMap={true} />
            </div>
            <div className='flex flex-1'>
                {tab === 'map' && <div>Map</div>}
                {tab === 'list' && <MainContentList />}
            </div>
        </div>
    );
};

const columns: DataTableColumns = [
    {
        columnId: 'sampleId',
        title: '',
        isUnique: true,
    },
    {
        columnId: 'sampleName',
        title: 'Sample Name',
        width: '20%',
    },
    {
        columnId: 'kitId',
        title: 'Kit ID',
        width: '20%',
    },
    {
        columnId: 'habitat',
        title: 'Habitat',
        width: '20%',
    },
    {
        columnId: 'sampler',
        title: 'Sampler',
        width: '20%',
        renderer(params) {
            return (
                <div className='flex items-center gap-2'>
                    <PersonIcon className='fill-primary' />
                    <div>{(params.cellData as ReactNode) || '-'}</div>
                </div>
            );
        },
    },
    {
        columnId: 'fieldData',
        title: 'Field Data',
        width: '20%',
        renderer(params) {
            const fieldDataStatus = params.cellData as string;
            return <FieldDataStatusIndicator status={fieldDataStatus as FieldDataStatus} />;
        },
    },
];

const MainContentList = () => {
    const [tableState, setTableState] = useState<Partial<DataTableState>>({
        rowsPerPage: Number.POSITIVE_INFINITY,
        hiddenColumnIds: new Set(['sampleId']),
    });
    const { samplingEvent, unsubmittedKits } = useSamplingEvent();
    const [totalCompleteSamples, setTotalCompleteSamples] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!unsubmittedKits) {
            return;
        }
        let totalCompleteSamples = 0;
        const data = unsubmittedKits
            .filter(kit => kit.status !== SamplingKitStatus.SUBMITTED)
            .map(kit => {
                // Field data status can be None, Incomplete, or Complete
                const fieldDataStatus = getSamplingKitFieldDataStatus(kit);
                if (fieldDataStatus === FieldDataStatus.COMPLETE) {
                    totalCompleteSamples++;
                }
                return [kit.id, kit.name, kit.barcode, kit.habitat, kit.sampler, fieldDataStatus];
            });
        setTotalCompleteSamples(totalCompleteSamples);
        setTableState({ ...tableState, data });
    }, [samplingEvent]);

    const handleRowClick = (row: TableRowData) => {
        const rowId = row[0] as string;
        dispatch(selectSamplingKit(rowId));
        setTableState({ ...tableState, selectedRowIds: new Set([rowId]) });
    };

    if (!tableState.data) {
        return <Loader />;
    }

    const table = (
        <DataTable
            state={tableState}
            columns={columns}
            emptyStateMessage={'No samples to display.'}
            className='flex-1 bg-white rounded-md mt-2'
            onRowSelect={handleRowClick}
        />
    );

    const toggle = (
        <div className='pl-8 flex gap-4 items-center overflow-auto'>
            <div>Samples</div>
            <div className='text-sm text-grey-60'>{totalCompleteSamples} complete sample (s)</div>
            <div>
                <BatchStatus status={SamplingEventBatchStatus.UNSUBMITTED} />
            </div>
        </div>
    );

    const content = (
        <ExpandableSection
            isExpanded={true}
            classNames={{
                wrapper: 'flex-1',
                toggle: 'left-[25px] top-[-2px] right-auto',
            }}
            expandedContent={
                <div className='flex flex-col flex-1'>
                    {toggle}
                    {table}
                </div>
            }
            collapsedContent={toggle}
        />
    );

    return <div className='flex flex-1 overflow-y-auto'>{content}</div>;
};

export default FieldDataMainContent;
