import React from 'react';
import { PrimaryButton, SecondaryButton } from 'src/shared/components/button/Button';
import useAppNavigation from 'src/shared/hooks/useAppNavigation';
import { SamplingEventStatus } from 'src/shared/types';
import { ReactComponent as PrintIcon } from 'src/assets/svg/general/print.svg';
import { SampleManifestFormViews } from '../../sample-manifest-form/SampleManifestForm';
import useSamplingEvent from '../../hooks/useSamplingEvent';

const StartPageBanner = () => {
    const navigation = useAppNavigation();
    const { isInProgress, selectedSamplingEventId, onInitiateSampleManifest, eventStatus } = useSamplingEvent();

    const hasSubmittedSamples =
        eventStatus === SamplingEventStatus.SAMPLES_PARTLY_SUBMITTED || eventStatus === SamplingEventStatus.SAMPLES_SUBMITTED;

    const onStartSamplingEvent = async () => {
        if (!isInProgress) {
            await onInitiateSampleManifest();
        }
        navigation.toSampleManifestForm({ view: SampleManifestFormViews.ADD_FIELD_DATA, samplingEventId: selectedSamplingEventId });
    };

    const getContent = () => {
        if (hasSubmittedSamples) {
            return (
                <>
                    <div className='flex flex-col gap-2 '>
                        <div className='text-primary flex text-lg font-bold'>You have submitted all data for this event.</div>
                        NatureMetrics is currently reviewing this data.
                        <br />
                        Once NatureMetrics has accepted the data, this event will be shown as complete.
                    </div>
                    <div className='flex flex-col gap-2'>
                        <SecondaryButton onClick={onStartSamplingEvent} disabled={true} className='flex gap-2'>
                            Print sampling data <PrintIcon className='fill-primary' />
                        </SecondaryButton>
                        <PrimaryButton onClick={onStartSamplingEvent} disabled={true}>
                            View sampling data &gt;
                        </PrimaryButton>
                    </div>
                </>
            );
        }

        if (isInProgress) {
            return (
                <>
                    <div className='flex flex-col gap-2'>
                        <div className='text-primary flex text-lg font-bold'>This event is in progress!</div>
                        <div>Click ‘resume sampling event’ to continue the submission of your project sampling data</div>
                    </div>
                    <div className='content-end'>
                        <PrimaryButton onClick={onStartSamplingEvent}>Resume sampling event &gt;</PrimaryButton>
                    </div>
                </>
            );
        }

        return (
            <>
                <div className='flex flex-col gap-2'>
                    <div className='text-primary flex text-lg font-bold'>Ready to start?</div>
                    Once you are ready to begin adding sampling data for this event, click the button on the right to begin the event.
                    <br />
                    After clicking this button, you are ready to being collecting field data for this event
                </div>
                <div className='content-end'>
                    <PrimaryButton onClick={onStartSamplingEvent}>Start sampling event &gt;</PrimaryButton>
                </div>
            </>
        );
    };

    return <div className='border-primary border-2 shadow-lg p-4 rounded-lg bg-white flex gap-6 justify-between'>{getContent()}</div>;
};

export default StartPageBanner;
