import React from 'react';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';

export enum SamplingEventBatchStatus {
    UNSUBMITTED = 'Unsubmitted',
    PENDING = 'Pending',
    COMPLETED = 'Completed',
}

type BatchStatusProps = {
    status: SamplingEventBatchStatus;
};

const BatchStatus = (props: BatchStatusProps) => {
    const { status } = props;

    return (
        <div className='flex items-center gap-1 bg-grey-20 rounded-full px-2'>
            <span className='text-sm'>{status}</span>
            <QuestionButtonHelp slug='batch-status' type='api' />
        </div>
    );
};

export default BatchStatus;
