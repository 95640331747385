import React from 'react';
import Input from '../input/Input';

type InputGroupProps = {
    inputFields: number[];
    placeholders?: string[];
    value: string;
    onChange: (value: string) => void;
};

const InputGroup = (props: InputGroupProps) => {
    const { inputFields, value, onChange, placeholders = [] } = props;

    const inputRefs = inputFields.map(() => React.createRef<HTMLInputElement>());
    const [inputValues, setInputValues] = React.useState<string[]>(inputFields.map(() => ''));

    const handleInputChange = (index: number, inputValue: string) => {
        // Validate input based on field index
        if (index === 0) {
            // Only allow letters in the first field
            inputValue = inputValue.replace(/[^a-zA-Z]/g, '').toUpperCase();
        } else {
            // Only allow numbers in the second and third fields
            inputValue = inputValue.replace(/[^0-9]/g, '');
        }

        inputValue = inputValue.toUpperCase();
        const newInputValues = [...inputValues];
        newInputValues[index] = inputValue.padEnd(inputFields[index], ' ');
        setInputValues(newInputValues);

        const newValue = newInputValues.join('');
        onChange(newValue);

        if (inputValue.length === inputFields[index] && index < inputFields.length - 1) {
            inputRefs[index + 1].current?.focus();
        } else if (inputValue.length === 0 && index > 0) {
            inputRefs[index - 1].current?.focus();
        }
    };

    React.useEffect(() => {
        if (value) {
            // Split the value into input fields
            const newInputValues = [];
            let index = 0;
            for (const inputField of inputFields) {
                newInputValues.push(value.slice(index, index + inputField));
                index += inputField;
            }
            setInputValues(newInputValues);
        } else {
            setInputValues(inputFields.map(() => ''));
        }
    }, [value]);

    const onPaste = (index: number, e: React.ClipboardEvent<HTMLInputElement>) => {
        let pastedValue = e.clipboardData.getData('text').toUpperCase();

        pastedValue = pastedValue.replace(/[^a-zA-Z0-9]/g, '');

        // Get the caret position
        const cursorPosition = e.currentTarget.selectionStart || 0;

        // Get the current input value
        const currentValue = inputValues[index];
        // Get the value before the cursor position
        const valueBeforeCursor = currentValue.slice(0, cursorPosition);

        // Get the value after the cursor position

        const valueAfterCursor = currentValue.slice(cursorPosition);

        // Get the new value
        const newValue = valueBeforeCursor + pastedValue + valueAfterCursor;

        // If the new value is greater than the input field length, then split the new value into input fields
        if (newValue.length > inputFields[index]) {
            const newInputValues = [];
            let index = 0;
            for (const inputField of inputFields) {
                newInputValues.push(newValue.slice(index, index + inputField));
                index += inputField;
            }
            setInputValues(newInputValues);
            onChange(newValue);
        }
    };

    return (
        <div className='flex items-center'>
            {inputFields.map((inputFieldLength, index) => (
                <React.Fragment key={index}>
                    <div style={{ width: `${inputFieldLength * 14 + 25}px` }}>
                        <Input
                            ref={inputRefs[index]}
                            value={inputValues[index].trim()}
                            onChange={e => handleInputChange(index, e.target.value)}
                            maxLength={inputFieldLength}
                            hideMaxLengthLabel={true}
                            className='display-inline-block'
                            placeholder={placeholders[index]}
                            onPaste={e => onPaste(index, e)}
                            width={`${inputFieldLength * 10}px`}
                            role='textbox'
                            style={{ textTransform: 'uppercase' }}
                        />
                    </div>
                    {index < inputFields.length - 1 && <span>-</span>}
                </React.Fragment>
            ))}
        </div>
    );
};

export default InputGroup;
