import { FieldDataStatus } from 'src/app/samples/sample-manifest/sample-manifest-form/views/field-data/components/field-data-main-content/components/field-data-status-indicator/FieldDataStatusIndicator';
import { SamplingKit } from '../types';

export enum KitType {
    Aquatic = 'Aquatic',
    AquaticBacteria = 'Aquatic bacteria',
    Soil = 'Soil',
    MarineSediment = 'Marine sediment',
    BulkInvertebrates = 'Bulk Invertebrates',
}

const kitTypeMapping: { [key: string]: KitType } = {
    ASD: KitType.AquaticBacteria,
    AMI: KitType.Aquatic,
    BSD: KitType.Aquatic,
    PSD: KitType.Aquatic,
    PMI: KitType.Aquatic,
    PMD: KitType.Aquatic,
    AMD: KitType.Aquatic,
    PSI: KitType.Aquatic,
    BSI: KitType.Aquatic,
    ASI: KitType.Aquatic,
    BPI: KitType.Aquatic,
    IVG: KitType.BulkInvertebrates,
    INV: KitType.BulkInvertebrates,
    MSB: KitType.MarineSediment,
    SRB: KitType.MarineSediment,
    MSC: KitType.MarineSediment,
    SRC: KitType.MarineSediment,
    SGB: KitType.Soil,
    SSC: KitType.Soil,
    SSB: KitType.Soil,
    SGC: KitType.Soil,
};

export const getKitTypeFromBarcode = (barcode: string): KitType | undefined => {
    const barcodePrefix = barcode.slice(0, 3);

    return kitTypeMapping[barcodePrefix];
};

export const isFullLengthBarcode = (barcode: string): boolean => {
    return barcode.replaceAll(' ', '').length === 10;
};

export const isValidKitBarcode = (kitBarcode: string): boolean => {
    return /^[A-Z]{3}/.test(kitBarcode);
};

export const getSamplingKitFieldDataStatus = (kit: SamplingKit) => {
    const kitType = getKitTypeFromBarcode(kit.barcode);
    const isAquaticKit = kitType === 'Aquatic' || kitType === 'Aquatic bacteria';
    const isBulkInvertebrateKit = kitType === 'Bulk Invertebrates';

    const valuesToCheck = [kit.barcode.trim(), kit.sampler, kit.latitude, kit.longitude, kit.habitat];

    if (isAquaticKit) {
        valuesToCheck.push(String(kit.volumeFiltered || ''));
    }

    if (isBulkInvertebrateKit) {
        valuesToCheck.push(kit.collectionMethod);
    }

    if (isAquaticMaxiKit(kit.barcode)) {
        valuesToCheck.push(String(kit.combinedVolumeFiltered || ''));
    }

    const filledValuesCount = valuesToCheck.filter(Boolean).length;

    return filledValuesCount === 0
        ? FieldDataStatus.NONE
        : valuesToCheck.length === filledValuesCount
        ? FieldDataStatus.COMPLETE
        : FieldDataStatus.INCOMPLETE;
};

export const isKitFieldDataCompleted = (kit: SamplingKit) => {
    if (getSamplingKitFieldDataStatus(kit) !== FieldDataStatus.COMPLETE) {
        return false;
    }

    const valuesToCheck = [kit.name];

    const filledValuesCount = valuesToCheck.filter(Boolean).length;

    return filledValuesCount === valuesToCheck.length;
};

// Format: ABC0100001 to ABC-01-00001
export const formattedKitBarcode = (barcode: string): string => {
    return `${barcode.slice(0, 3)}-${barcode.slice(3, 5)}-${barcode.slice(5)}`;
};

type TestType = {
    habitatName: string;
    assayName: string;
    habitatAssayKey: string;
};

type KitHabitatTestTypesMapping = {
    kitType: KitType;
    kit: string;
    testsTypes: TestType[];
};

export const kitsHabitatTestTypesMapping: KitHabitatTestTypesMapping[] = [
    {
        kitType: KitType.Aquatic,
        kit: 'Aquatic Kit',
        testsTypes: [
            {
                habitatName: 'Freshwater',
                assayName: 'Vertebrates',
                habitatAssayKey: 'freshwater-vertebrates',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Fish',
                habitatAssayKey: 'freshwater-fish',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Mammals',
                habitatAssayKey: 'freshwater-mammals',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Insects',
                habitatAssayKey: 'freshwater-insects',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Unionid Mussels',
                habitatAssayKey: 'freshwater-unionid-mussels',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Bivalves',
                habitatAssayKey: 'freshwater-bivalves',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Fish (from swab)',
                habitatAssayKey: 'freshwater-fish-swab',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Chinese mitten crab',
                habitatAssayKey: 'freshwater-CMC',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Arctic char',
                habitatAssayKey: 'freshwater-arctic-char',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'European eel',
                habitatAssayKey: 'freshwater-european-eel',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Crayfish plague',
                habitatAssayKey: 'freshwater-crayfish-plague',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Signal crayfish',
                habitatAssayKey: 'freshwater-signal-crayfish',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'White-clawed crayfish',
                habitatAssayKey: 'freshwater-WC-crayfish',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Zebra mussel',
                habitatAssayKey: 'freshwater-zebra-mussel',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Dreissenid mussels',
                habitatAssayKey: 'freshwater-dreissenid-mussels',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Chytrid fungus (B.dendrobatidis)',
                habitatAssayKey: 'freshwater-chytrid-bden',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Chytrid fungus (B.salamandrivorans)',
                habitatAssayKey: 'freshwater-chytrid-bsal',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Burbot',
                habitatAssayKey: 'freshwater-burbot',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Redside dace',
                habitatAssayKey: 'freshwater-redside-dace',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Silver shiner',
                habitatAssayKey: 'freshwater-silver-shiner',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Lake sturgeon',
                habitatAssayKey: 'freshwater-lake-sturgeon',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Blandings turtle',
                habitatAssayKey: 'freshwater-blandings-turtle',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Brook trout',
                habitatAssayKey: 'freshwater-brook-trout',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Brown trout',
                habitatAssayKey: 'freshwater-brown-trout',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Rainbow trout',
                habitatAssayKey: 'freshwater-rainbow-trout',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Round whitefish',
                habitatAssayKey: 'freshwater-round-whitefish',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Signal crayfish (Pacifastacus leniusculus)',
                habitatAssayKey: 'freshwater-crayfish-pacifastcus-ieniusculus',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Fish',
                habitatAssayKey: 'marine-water-fish',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Mammals',
                habitatAssayKey: 'marine-water-mammals',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Vertebrates',
                habitatAssayKey: 'marine-water-vertebrates',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Eukaryotes',
                habitatAssayKey: 'marine-water-eukaryotes',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Invertebrates',
                habitatAssayKey: 'marine-water-invertebrates',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Chinese mitten crab',
                habitatAssayKey: 'marine-water-CMC',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Atlantic herring',
                habitatAssayKey: 'marine-water-herring',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Rainbow trout',
                habitatAssayKey: 'marine-water-rainbow-trout',
            },
        ],
    },
    {
        kitType: KitType.AquaticBacteria,
        kit: 'Aquatic Bacteria Kit',
        testsTypes: [
            {
                habitatName: 'Freshwater',
                assayName: 'Bacteria',
                habitatAssayKey: 'freshwater-bacteria',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Bacteria',
                habitatAssayKey: 'marine-water-bacteria',
            },
        ],
    },
    {
        kitType: KitType.MarineSediment,
        kit: 'Marine Sediment Kit',
        testsTypes: [
            {
                habitatName: 'Marine sediment',
                assayName: 'Eukaryotes',
                habitatAssayKey: 'marine_sediment-eukaryotes',
            },
            {
                habitatName: 'Marine sediment',
                assayName: 'Bacteria',
                habitatAssayKey: 'marine-sediment-bacteria',
            },
            {
                habitatName: 'Marine sediment',
                assayName: 'Invertebrates',
                habitatAssayKey: 'marine-sediment-invertebrates',
            },
        ],
    },
    {
        kitType: KitType.BulkInvertebrates,
        kit: 'bulk_invertebrate',
        testsTypes: [
            {
                habitatName: 'Terrestrial',
                assayName: 'Invertebrates (from tissue samples)',
                habitatAssayKey: 'terrestrial-invertebrates-from-tissue-samples',
            },
            {
                habitatName: 'Marine water',
                assayName: 'Invertebrates',
                habitatAssayKey: 'marine_water-invertebrates',
            },
            {
                habitatName: 'Freshwater',
                assayName: 'Invertebrates',
                habitatAssayKey: 'freshwater-invertebrates',
            },
        ],
    },
    {
        kitType: KitType.Soil,
        kit: 'Soil Kit',
        testsTypes: [
            {
                habitatName: 'Soil',
                assayName: 'Invertebrates',
                habitatAssayKey: 'soil-invertebrates',
            },
            {
                habitatName: 'Soil',
                assayName: 'Fungi',
                habitatAssayKey: 'soil-fungi',
            },
            {
                habitatName: 'Soil',
                assayName: 'Bacteria',
                habitatAssayKey: 'soil-bacteria',
            },
        ],
    },
];

export const getTestTypes = (barcode: string, habitatName: string): TestType[] | undefined => {
    const kitType = getKitTypeFromBarcode(barcode);
    const kit = kitsHabitatTestTypesMapping.find(k => k.kitType === kitType);
    if (!kit) {
        return undefined;
    }
    const habitatTests = kit.testsTypes.filter(test => test.habitatName === habitatName);
    return habitatTests.length > 0 ? habitatTests : undefined;
};

export const getHabitatOptionsByKitType = (kitType: KitType): { label: string; value: string }[] => {
    const kit = kitsHabitatTestTypesMapping.find(k => k.kitType === kitType);
    if (!kit) {
        return [];
    }
    const habitatNames = kit.testsTypes.map(test => test.habitatName);
    const uniqueHabitatNames = Array.from(new Set(habitatNames));
    return uniqueHabitatNames.map(habitatName => ({
        label: habitatName,
        value: habitatName,
    }));
};

// If the aquatic kit entered in the kit barcode field contains the prefixes ‘AMI, PMI, PMD or AMD’, then it is aquatic maxi kit
export const isAquaticMaxiKit = (barcode: string): boolean => {
    return ['AMI', 'PMI', 'PMD', 'AMD'].includes(barcode.slice(0, 3));
};
