import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import PageNotFound from 'src/shared/components/page-not-found/PageNotFound';
import { getSamplesRoutesAvailability } from 'src/routes/state/routesSelector';
import { useAppSelector } from 'src/store';
import { IndividualRouteSetting, SamplesViews } from 'src/routes/routesConfig';
import SampleManifest from './sample-manifest/SampleManifest';

export const samplesRouteSettings: { [key in SamplesViews]: IndividualRouteSetting } = {
    samples: {
        name: 'Samples',
        path: '/',
        handler: <div>Samples</div>,
    },
    sampleManifest: {
        name: 'Sample Manifest',
        path: '/sample-manifest/*',
        handler: <SampleManifest />,
    },
};

const Samples = () => {
    const routesConfig = useAppSelector(getSamplesRoutesAvailability);

    if (!routesConfig) {
        return null;
    }

    return (
        <Routes>
            {Object.keys(routesConfig).map(routeKey => {
                const isAvailable = routesConfig[routeKey as SamplesViews];
                const routeSetting = samplesRouteSettings[routeKey as SamplesViews];

                return (
                    <Route
                        path={routeSetting.path}
                        element={
                            <ProtectedRoute isAllowed={isAvailable} redirectPath={'/projects'}>
                                {routeSetting.handler}
                            </ProtectedRoute>
                        }
                        key={routeSetting.name}
                    />
                );
            })}
            <Route path='/*' element={<PageNotFound />} />
        </Routes>
    );
};
export default Samples;
