import { useAppDispatch, useAppSelector } from 'src/store';
import useProject from 'src/shared/hooks/useProject';
import { CMSHabitatAssayTypeWithoutImage, SamplingEventStatus, SamplingKit, SamplingKitStatus } from 'src/shared/types';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { isKitFieldDataCompleted } from 'src/shared/helpers/kitHelpers';
import { getCurrentSampleManifestState } from '../state/sampleManifestSelector';
import {
    useInitiateSampleManifestEventMutation,
    useSamplingEventQuery,
    useSubmitSampleKitsForProcessingMutation,
    useUpdateSampleKitMutation,
} from '../state/api/sampleManifestGraphSlice';
import { setselectedSamplingEventId } from '../state/sampleManifestSlice';

const useSamplingEvent = () => {
    const { currentProjectSummary, currentProjectId } = useProject();
    const dispatch = useAppDispatch();
    const sampleManifestState = useAppSelector(getCurrentSampleManifestState);
    const [searchParams] = useSearchParams();
    const samplingEventIdFromUrl = searchParams.get('samplingEventId') as string;
    const { selectedSamplingKitId, selectedSamplingEventId = samplingEventIdFromUrl } = sampleManifestState;
    const [saveUpdatedSamplingKit] = useUpdateSampleKitMutation();
    const [submitSamplingKitsMutation] = useSubmitSampleKitsForProcessingMutation();
    const [initiateSampleManifestEvent] = useInitiateSampleManifestEventMutation();

    React.useEffect(() => {
        if (!selectedSamplingEventId && samplingEventIdFromUrl) {
            dispatch(setselectedSamplingEventId(samplingEventIdFromUrl));
        }
    }, [samplingEventIdFromUrl, selectedSamplingEventId]);

    const { currentData, isFetching } = useSamplingEventQuery(
        {
            samplingEventId: selectedSamplingEventId,
            projectId: currentProjectId || '',
        },
        {
            skip: !currentProjectId || !selectedSamplingEventId,
        }
    );

    const selectedSamplingKit = currentData?.kits.find(kit => kit.id === selectedSamplingKitId);

    const saveSamplingKit = async (samplingKit: SamplingKit) => {
        const existingKit = currentData?.kits.find(kit => kit.id === samplingKit.id);
        // Merge the existing kit and the new sampling kit, then filter out 'status' and null values
        const samplingKitToSave = Object.fromEntries(
            Object.entries({ ...existingKit, ...samplingKit }).filter(([key, value]) => key !== 'status' && value !== null)
        );

        return await saveUpdatedSamplingKit({
            projectId: currentProjectId || '',
            samplingEventId: selectedSamplingEventId || '',
            input: samplingKitToSave as Omit<SamplingKit, 'status'>,
        });
    };

    const submitSamplingKits = async () => {
        if (!currentProjectId || !selectedSamplingEventId) {
            return;
        }

        const readyToSubmitKits = currentData?.kits.filter(kit => kit.testTypes.length) || [];

        const kitIds = readyToSubmitKits.map(kit => kit.id);
        try {
            await submitSamplingKitsMutation({
                projectId: currentProjectId,
                samplingEventId: selectedSamplingEventId,
                kitIds,
            });
            return true;
        } catch (e) {
            return e;
        }
    };

    const onInitiateSampleManifest = async () => {
        if (!currentProjectId || !selectedSamplingEventId) {
            return;
        }

        await initiateSampleManifestEvent({
            projectId: currentProjectId,
            samplingEventId: selectedSamplingEventId,
        });
    };

    const getAllUnsubmittedKits = () => {
        return currentData?.kits.filter(kit => kit.status !== SamplingKitStatus.SUBMITTED) || [];
    };

    const getFieldDataCompletedKits = () => {
        return currentData?.kits.filter(isKitFieldDataCompleted) || [];
    };

    const getReadyToAddTestKits = () => {
        return getFieldDataCompletedKits().filter(kit => kit.status !== SamplingKitStatus.SUBMITTED);
    };

    const getReadyToSubmitKits = () => {
        return getReadyToAddTestKits().filter(kit => kit.testTypes.length);
    };

    const getRemainingTests = (): {
        testType: CMSHabitatAssayTypeWithoutImage;
        total: number;
        remaining: number;
    }[] => {
        const estimatedSampleCountForTestTypes = currentProjectSummary?.estimatedSampleCountForTestTypes;

        if (!estimatedSampleCountForTestTypes) {
            return [];
        }

        return estimatedSampleCountForTestTypes.map(test => {
            const completedSampleCount = currentData?.kits.reduce((acc, kit) => {
                return acc + kit.testTypes.filter(t => t.habitatAssayKey === test.type.habitatAssayKey).length;
            }, 0);

            return {
                testType: test.type,
                total: test.count,
                remaining: test.count - (completedSampleCount || 0),
            };
        });
    };

    return {
        samplingEvent: currentData,
        selectedSamplingKitId,
        samplingKits: currentData?.kits || [],
        selectedSamplingEventId,
        isFetching,
        estimatedSampleCount: currentProjectSummary?.estimatedSampleCounts || 0,
        selectedSamplingKit,
        isInProgress: currentData?.status !== SamplingEventStatus.INITIATED,
        eventStatus: currentData?.status,
        fieldDataCompletedKits: getFieldDataCompletedKits(),
        kitsReadyToAddTests: getReadyToAddTestKits(),
        unsubmittedKits: getAllUnsubmittedKits(),
        remainingTests: getRemainingTests(),
        readyToSubmitKits: getReadyToSubmitKits(),
        saveSamplingKit,
        onInitiateSampleManifest,
        submitSamplingKits,
    };
};

export default useSamplingEvent;
