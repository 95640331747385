import * as React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import styles from './BasicDatePicker.module.scss';

type BasicDatePickerProps = {
    label?: string;
    value: Date | null;
    disablePast?: boolean;
    disableFuture?: boolean;
    format?: string;
    minDate?: Moment;
    hasError?: boolean;
    onChange: (value: Date | undefined) => void;
};

const BasicDatePicker = (props: BasicDatePickerProps) => {
    const { label, value, onChange, disablePast, disableFuture, minDate, hasError, format = 'DD/MM/YYYY' } = props;

    const className = classNames(styles.basicDatePicker, {
        [styles.basicDatePickerError]: hasError,
    });

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={label}
                value={value ? moment(value || null) : null}
                onChange={value => onChange(value?.toDate())}
                className={className}
                format={format}
                disablePast={disablePast}
                disableFuture={disableFuture}
                minDate={minDate}
            />
        </LocalizationProvider>
    );
};

export default BasicDatePicker;
