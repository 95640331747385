import React from 'react';
import CopyToClipboard from 'src/shared/components/copy-to-clipboard/CopyToClipboard';
import { useAppSelector } from 'src/store';
import { getselectedSamplingEventId } from '../../../state/sampleManifestSelector';

const SamplingEventPinCode = () => {
    const selectedSamplingEventId = useAppSelector(getselectedSamplingEventId);

    const idToDisplay = selectedSamplingEventId.slice(0, 3) + '-' + selectedSamplingEventId.slice(3);
    return (
        <div className='flex flex-col gap-2'>
            <div className='text-grey-60 text-sm'>Sampling event PIN Code</div>
            <div className='flex gap-3 items-center'>
                <div className='text-2xl'>{idToDisplay}</div>
                <div>
                    <CopyToClipboard text={selectedSamplingEventId} />
                </div>
            </div>
        </div>
    );
};

export default SamplingEventPinCode;
