import React, { useState, useEffect } from 'react';
import useSamplingEvent from 'src/app/samples/sample-manifest/hooks/useSamplingEvent';
import SelectMultiple from 'src/shared/components/select-multiple/SelectMultiple';
import { getTestTypes } from 'src/shared/helpers/kitHelpers';
import { CMSHabitatAssayTypeWithoutImage } from 'src/shared/types';

type AddTestsDropdownProps = {
    samplingKitId: string;
};

type HabitatTests = {
    remaining: number;
    testType: CMSHabitatAssayTypeWithoutImage;
    total: number;
};

const AddTestsDropdown = ({ samplingKitId }: AddTestsDropdownProps) => {
    const { remainingTests, fieldDataCompletedKits, saveSamplingKit } = useSamplingEvent();
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [habitatTests, setHabitatTests] = useState<HabitatTests[]>([]);
    const samplingKit = fieldDataCompletedKits.find(kit => kit.id === samplingKitId);

    useEffect(() => {
        if (samplingKit) {
            setSelectedValues(testTypes.map(testType => testType.habitatAssayKey));
        }
    }, [samplingKit]);

    if (!samplingKit) {
        return null;
    }

    const { habitat, testTypes } = samplingKit;
    const habitatAssayTypeOptions = habitatTests.map(test => ({
        label: (
            <div className='flex flex-col'>
                <span className=''>{test.testType.habitatName}</span>
                <div className='flex gap-4 justify-between items-center'>
                    <span className='font-bold'>{test.testType.assayName}</span>
                    <span className='text-primary'>{test.remaining}</span>
                </div>
            </div>
        ),
        value: test.testType.habitatAssayKey,
        disabled:
            //disable if the test is not already added and total is greater than 3 and remianing is 0
            (selectedValues.length && test.remaining === 0 && !selectedValues.includes(test.testType.habitatAssayKey)) ||
            (selectedValues.length >= 3 && !selectedValues.includes(test.testType.habitatAssayKey)),
    }));

    const handleChange = (values: string[]) => {
        setSelectedValues(values);
    };

    const handleClose = async () => {
        const newTestTypes = habitatTests.filter(test => selectedValues.includes(test.testType.habitatAssayKey)).map(test => test.testType);
        await saveSamplingKit({
            ...samplingKit,
            testTypes: newTestTypes,
        });
    };

    const handleOpen = () => {
        const testTypes = getTestTypes(samplingKit.barcode, habitat);
        const habitatTests = remainingTests.filter(
            test => test.testType.habitatName === habitat && testTypes?.some(tt => tt.habitatAssayKey === test.testType.habitatAssayKey)
        );

        setHabitatTests(habitatTests);
    };

    return (
        <SelectMultiple
            options={habitatAssayTypeOptions}
            onChange={handleChange}
            values={selectedValues}
            onClose={handleClose}
            onOpen={handleOpen}
            noSelectionText='Add'
            showSelectAll={false}
            bottomText='Select up to 3 tests'
        />
    );
};

export default AddTestsDropdown;
