import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CMSHabitatAssayType,
    ProjectDefinitionCompletionStatus,
    ProjectSamplingCadenceType,
    SamplingEvent,
    ProjectDefinitionSample,
} from 'src/shared/types';

export type NewProjectDefinitionFormState = {
    // Basic information
    projectId: string;
    projectName: string;
    projectCode: string;
    subscriptionType: string;
    country: string;
    projectEndDate: string;
    area: string;
    projectDefinitionCompletionStatus: ProjectDefinitionCompletionStatus | null;
    // Project contact
    projectLeadName: string;
    projectLeadEmail: string;
    projectLeadRole: string;

    // Project aims
    projectType: string;
    targetOutcomes: string;

    // Sampling cadence
    samplingCadence: ProjectSamplingCadenceType | string;
    samplingEventsPerYear: number;
    samplingEvents: Pick<SamplingEvent, 'name' | 'fromDate' | 'toDate'>[];
    sampleGroups: string[];

    // Number of Sampling Years
    projectLifespanYears: number;

    // Species of interest
    speciesOfInterest: string[];

    estimatedSampleCountForTestTypes: {
        type: Pick<CMSHabitatAssayType, 'assayName' | 'habitatAssayKey' | 'habitatName'>;
        count: number;
    }[];

    // Habitat assay types
    habitatAssayTypes: Pick<CMSHabitatAssayType, 'assayName' | 'habitatAssayKey' | 'habitatName'>[];

    // Samples
    initialSampleEstimate: number;
    uploadedFileContent: string;
    uploadedFileErrors: string[];
    initialSampleEntries: ProjectDefinitionSample[];

    // Notify users on change
    notifyUsersOnChange: boolean;
};

export const defaultProjectDefinitionFormState = {
    projectId: '',
    projectName: '',
    projectCode: '',
    subscriptionType: 'Basic',
    country: '',
    projectEndDate: '',
    area: '',
    projectLeadRole: '',
    projectLeadName: '',
    projectLeadEmail: '',
    projectType: '',
    targetOutcomes: '',
    samplingCadence: '',
    projectLifespanYears: 0,
    samplingEventsPerYear: 0,
    samplingEvents: [],
    initialSampleEstimate: 0,
    sampleGroups: [],
    speciesOfInterest: [],
    estimatedSampleCountForTestTypes: [],
    habitatAssayTypes: [],
    projectDefinitionCompletionStatus: null,
    uploadedFileContent: '',
    uploadedFileErrors: [],
    initialSampleEntries: [],
    notifyUsersOnChange: false,
};

export type NewProjectDefinitionUIState = {
    formStep: number;
    formState: NewProjectDefinitionFormState;
};

type ProjectDefinitionState = {
    uiState: NewProjectDefinitionUIState;
};

const initialState: ProjectDefinitionState = {
    uiState: { formStep: 0, formState: { ...defaultProjectDefinitionFormState } },
};

const newProjectDefinitionSlice = createSlice({
    name: 'newProjectDefinition',
    initialState,
    reducers: {
        setCurrentStep(state, action: PayloadAction<number>) {
            state.uiState.formStep = action.payload;
        },
        setFormState(state, action: PayloadAction<Partial<NewProjectDefinitionFormState>>) {
            state.uiState.formState = { ...state.uiState.formState, ...action.payload };
        },

        reset(state) {
            // eslint-disable-next-line
            state.uiState = { ...initialState.uiState };
        },
    },
});

export default newProjectDefinitionSlice.reducer;

export const { setFormState, setCurrentStep, reset } = newProjectDefinitionSlice.actions;
